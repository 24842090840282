import React from "react"

import "../styles/index.scss"

class IndexPage extends React.Component {
    render() {
        return (
            <div className="main-container">
                <div className="section-1">
                    <div className="message-sender">KCBB @ 9MAY2020</div>
                    <div className="message-divider"></div>
                    <div className="message-content">
                        I am working on the new layout. <span>slowly.</span>
                    </div>
                </div>
                <div className="section-2">
                    <a href="http://mtr.hk365day.com">Go to current version</a>
                    <a href="https://www.facebook.com/mtrexpo">
                        Our Facebook Page
                    </a>
                </div>
                <div className="section-3">
                    <picture>
                        <source
                            srcSet="images/sitelogo-light.svg"
                            media="(prefers-color-scheme: dark)"
                        ></source>
                        <source
                            srcSet="images/sitelogo-color.svg"
                            media="(prefers-color-scheme: light), (prefers-color-scheme: no-preference)"
                        ></source>
                        <img alt="MTR expo" src="images/sitelogo-color.svg"></img>
                    </picture>
                </div>
            </div>
        )
    }
}

export default IndexPage
